<template>
  <div class="vw-100 vh-100 row g-0">
    <div class="position-absolute top-0 start-0 text-light px-1">
      {{ version }}
    </div>
    <div class="col-12 col-sm-4 col-lg-3 col-xl-2 bg-primary p-3">
      <div class="d-flex flex-column h-100">
        <div class="mt-2 mb-3 p-3 bg-light rounded" v-if="$theme === 'csa'">
          <img src="../assets/csa.svg" class="w-100">
        </div>
        <div class="mt-2 mb-3 rounded" v-else-if="$theme === 'sara'">
          <img src="../assets/logo_sara.png" class="w-100">
        </div>
        <div class="lh-1 text-light text-center">
          <div class="fs-5 fw-bolder">¡Bienvenido!</div>
        </div>
        <div class="flex-grow-1 mt-3">
          <div>
            <label class="form-label mb-0 text-light">Email</label>
            <input name="email" type="text" class="form-control" v-model="email">
            <form-error-login name="email" :errors="errors" class="mt-1"></form-error-login>
          </div>
          <div>
            <label class="form-label mb-0 text-light">Contraseña</label>
            <input name="password" type="password" class="form-control" v-model="password"
                   @keyup.enter="login">
            <form-error-login name="password" :errors="errors" class="mt-1"></form-error-login>
          </div>
          <div class="text-center">
            <button :disabled="working"
                    class="btn btn-outline-light w-100 my-3" @click="login">
              Iniciar sesión
            </button>
            <span class="">
              <router-link to="/recuperarContrasena" class="link-light">¿Olvidaste tu contraseña?</router-link>
            </span>
          </div>
        </div>
        <div class="p-3">
          <img src="../assets/dev_by_white.png" class="w-100">
        </div>
      </div>

    </div>
    <div class="d-none d-sm-block col-sm-8 col-lg-9 col-xl-10 login-bg">

    </div>
  </div>
</template>

<script>
import axios from "axios";
import FormErrorLogin from "../components/utils/FormErrorLogin.vue";
import {VERSION} from "../code/app";

export default {
  name: 'LoginView',
  components: {
    FormErrorLogin
  },
  data() {
    return {
      project_name: import.meta.env.VITE_NAME ?? 'Tresfactorial',
      errors: {},
      email: null,
      password: null,
      working: false,
      version: VERSION,
    }
  },
  methods: {
    login() {
      this.errors = [];
      this.working = true;
      axios.post('/login', {
        email: this.email,
        password: this.password,
      }).then(response => {
        this.$router.replace('/');
      }).catch(error => {
        this.errors = error?.response?.data?.errors ?? {};
        this.working = false;
      });
    }
  }
}
</script>

<style>
.login-bg {
  background: url("../assets/login_bg.jpg") center;
  background-size: cover;
}
</style>
